<template>
	<form>
		<AvatarSelector
			@selectAvatar="avatar = $event"
			@selectColor="avatarColor = $event"
			:selectedAvatar="avatar"
			:selectedColor="avatarColor"
			/>
		<div class="form-group text-right">
			<small>{{ $t('register.mandatoryLabel') }}</small>
		</div>
		<div class="form-group">
			<label for="email">{{ $t('register.email') }}: *</label>
			<EmailInput
				id="email"
				v-model="email"
				:msgs="msgs"
				class="form-control"
				:required="true"
			/>
			<ComponentMessage :msgs="msgs" forComponent="email" />
		</div>
		<div class="form-group">
			<label for="username">{{ $t('register.username') }}: *</label>
			<TextInput
				id="username"
				v-model="username"
				:msgs="msgs"
				class="form-control"
				maxlength="255"
				:required="true"
				@change="usernameChanged = true"
			/>
			<ComponentMessage :msgs="msgs" forComponent="username" />
		</div>
		<div class="form-group">
			<label for="name">{{ $t('register.name') }}:</label>
			<TextInput
				id="name"
				v-model="name"
				:msgs="msgs"
				class="form-control"
				maxlength="255"
			/>
			<ComponentMessage :msgs="msgs" forComponent="firstName" />
		</div>
		<div class="form-group">
			<label for="phone">{{ $t('register.phone') }}:</label>
			<PhoneInput
				id="phone"
				name="phone"
				v-model="phone"
				:msgs="msgs"
				class="form-control"
				maxlength="255"
			/>
			<ComponentMessage :msgs="msgs" forComponent="phone" />
		</div>

		<div class="row">
			<div class="col-sm-6 form-group">
				<label for="password">{{ $t('register.password') }}: *</label>
				<PasswordInput
					id="password"
					v-model="password1"
					:msgs="msgs"
					class="form-control"
					maxlength="255"
				/>
				<ComponentMessage :msgs="msgs" forComponent="password1" />

				<small>{{ $t('invalid.password') }}</small>
			</div>

			<div class="col-sm-6 form-group">
				<label for="password2">{{ $t('register.password2') }}: *</label>
				<PasswordInput
					id="password2"
					v-model="password2"
					:msgs="msgs"
					class="form-control"
					maxlength="255"
					:password1="password1"
				/>
				<ComponentMessage :msgs="msgs" forComponent="password2" />
			</div>
		</div>


		<div class="media">
			<Checkbox id="accepted" v-model="accepted" :binary="true" class="mr-2" />
			<label for="accepted" class="form-check-label media-body" v-html="$t('register.acceptMessage') + '*'"/>
		</div>
		<ComponentMessage :msgs="msgs" forComponent="accepted" />

		<div class="form-group buttonRow mt-5">
			<button @click="register()" class="btn btn-primary" type="button">
				<font-awesome-icon icon="check" />
				{{ $t('register.registerButton') }}
			</button>

			<router-link to="login" tag="a">
				{{ $t('register.cancelButton') }}
			</router-link>
		</div>
	</form>
</template>

<script>
import TextInput from '@/components/inputs/TextInput.vue';
import Checkbox from 'primevue/checkbox';
import AvatarSelector from "@/components/profile/avatar/AvatarSelector.vue";
export default {
    name: "RegisterView",
    data() {
        return {
            name: "",
            email: "",
            phone: "",
						username: "",
						usernameChanged: false,
            password1: "",
            password2: "",
            accepted: false,
						/** @type string */
						avatar: null,
						/** @type string */
						avatarColor: null,
        };
    },
    props: {
        msgs: Object
    },
		mounted() {
			if(this.$route.query.email) {
				this.email = this.$route.query.email;
			}
		},
		watch: {
			email() {
				if(!this.usernameChanged) {
					this.username = (this.email ? this.email.split('@')[0] : '').replaceAll(/[^0-9a-zA-Z_]/gi, '');
				}
			},
		},
		methods: {
        async register() {
					const result = await this.$rest.register({
						"name": this.name,
						"email": this.email,
						"phone": this.phone,
						"username": this.username,
						"password1": this.password1,
						"password2": this.password2,
						"accepted": this.accepted,
						"avatar": this.avatar,
						"avatarColor": this.avatarColor,
					});

					if (result) {
						await this.$router.replace("login");
					}
				},
				cancel() {
					this.$router.replace("login");
        },
    },
    components: { AvatarSelector, TextInput, Checkbox }
};
</script>
