<template>
	<div class="avatar-selector">
		<div class="avatar-selector-row">
			<div class="avatar-selector-left">
				<div class="avatar-selected" :style="{ backgroundColor: color }">
					<img :src="`avatars/${avatar}.png`" />
				</div>
				<div class="colors" v-if="!isOperator">
					<div :class="{ selected: c === color, color: true }" v-for="c in colors" :key="c"
						:style="{ backgroundColor: c }" @click="selectColor(c)">
					</div>
				</div>
			</div>
			<div class="avatar-selector-right" v-if="!isOperator">
				<div class="avatar-selector-grid">
					<div :class="{ selected: a === avatar, avatar: true }" v-for="a in avatars" :key="a"
						@click="selectAvatar(a)" :style="{ backgroundColor: color }">
						<img :src="`avatars/${a}.png`" />
					</div>
				</div>

				<span class="attribution">
					{{ $t('avatarSelector.attribution') }}
					<a target="_blank" href="http://www.freepik.com">Freepik</a>
				</span>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'AvatarSelector',
	props: {
		selectedAvatar: String,
		selectedColor: String,
	},
	emits: ['selectAvatar', 'selectColor'],
	data() {
		return {
			avatars: ["m1", "m2", "m3", "f1", "f2", "f3"],
			colors: ["#B1CAD8", "#F7C0B5", "#B5E2C9", "#F4DA92"],
		}
	},
	methods: {
		selectAvatar(avatar) {
			this.$emit('selectAvatar', avatar)
		},
		selectColor(color) {
			this.$emit('selectColor', color)
		},
	},
	computed: {
		isOperator() {
			return this.selectedAvatar && this.selectedAvatar.includes("operator")
		},
		avatar() {
			return this.selectedAvatar || this.avatars[0];
		},
		color() {
			return this.selectedColor || this.colors[0];
		}
	},
	mounted() {
		this.$emit('selectAvatar', this.avatar);
		this.$emit('selectColor', this.color);
	}
}
</script>

<style>
.avatar-selector {
	padding: 1rem 1rem;
}


.avatar-selector-row {
	display: flex;
	flex-direction: row;
	gap: 1rem;
	align-items: center;
	justify-content: center;
}

.avatar-selector-left {
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.avatar-selector-right {
	flex: 1;

}

.avatar-selector-grid {
	justify-items: center;
	align-content: center;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	margin: 0 auto;
	gap: 1rem;
}

.avatar-selected {
	height: 150px;
	width: 150px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
}

.avatar-selected img {
	width: 100%;
}


.avatar {
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80px;
	height: 80px;
	cursor: pointer;
	position: relative;
}


/* Outline (bc safari likes to not work) */
.avatar.selected::before {
	content: "";
	position: absolute;
	top: -2px;
	right: -2px;
	bottom: -2px;
	left: -2px;
	border: 2px solid #333;
	border-radius: 50%;
}

.avatar img {
	border-radius: 50%;
	width: 100%;
}


.colors {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: 100%;
}

.color {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	cursor: pointer;
	position: relative;
}

/* Outline (bc safari likes to not work) */
.color.selected::before {
	content: "";
	position: absolute;
	top: -2px;
	right: -2px;
	bottom: -2px;
	left: -2px;
	border: 2px solid #333;
	border-radius: 50%;
}



.attribution {
	font-size: 0.6rem;
	color: #999;
	text-align: center;
	margin-top: 0.3rem;
	width: 100%;
	display: block;
}

.attribution a {
	color: #999;
}

.attribution a:hover {
	color: #666;
}


@media screen and (max-width: 600px) {
	.avatar-selector {
		padding: 1rem 0.5rem;
	}

	.avatar-selector-grid {
		gap: 0.5rem;
	}

	.avatar {
		width: 70px;
		height: 70px;
	}

	.avatar-selected {
		height: 120px;
		width: 120px;
	}


	.color {
		width: 20px;
		height: 20px;
	}
}

@media screen and (max-width: 480px) {
	.avatar {
		width: 60px;
		height: 60px;
	}

	.avatar-selected {
		height: 100px;
		width: 100px;
	}


	.color {
		width: 20px;
		height: 20px;
	}
}



@media screen and (max-width: 400px) {
	.avatar-selector {
		padding: 1rem 0.5rem;
	}


	.avatar-selector-row {
		flex-direction: column;
		gap: 0.5rem;
	}

	.avatar-selector-grid {
		gap: 0.5rem;
	}

	.avatar {
		width: 70px;
		height: 70px;
	}

	.avatar-selected {
		height: 120px;
		width: 120px;
	}


	.color {
		width: 20px;
		height: 20px;
	}
}
</style>
